@use 'assets/theme/variables/colors';
@use 'assets/theme/variables/spacers';
@use 'assets/theme/variables/sizes';
@use "assets/theme/variables/breakpoints";
@use "assets/theme/variables/mixins";

.root {
  height: fit-content;
  min-height: 100vh;
}

.header {
  background: colors.$mainBackgroundColor !important;
  max-height: sizes.$headerHeight;
  height: sizes.$headerHeight !important;
  z-index: sizes.$headerZIndex;
  padding: 0;
  display: flex;
  justify-content: center;
}

.content {
  height: fit-content;
  overflow-x: hidden;
}

.footer {
  z-index: sizes.$headerZIndex;
  display: flex;
  justify-content: center;
  padding: 0;
}
