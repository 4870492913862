@use "sass:math";

$spacer: 8px;

$spacer0: $spacer * 0;
$spacer1: math.div($spacer, 4);
$spacer2: math.div($spacer, 2);
$spacer3: $spacer;
$spacer4: $spacer * 1.5;
$spacer5: $spacer * 2;
$spacer6: $spacer * 2.5;
$spacer7: $spacer * 3;
$spacer8: $spacer * 3.5;
$spacer9: $spacer * 4;
$spacer10: $spacer * 4.5;
$spacer11: $spacer * 5;
$spacer12: $spacer * 5.5;
$spacer13: $spacer * 6;
$spacer14: $spacer * 6.5;
$spacer15: $spacer * 7;
$spacer16: $spacer * 7.5;
$spacer17: $spacer * 8;
$spacer18: $spacer * 8.5;
$spacer19: $spacer * 9;
$spacer20: $spacer * 9.5;
$spacer21: $spacer * 10;
