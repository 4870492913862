@use "assets/theme/variables/breakpoints";
@use "assets/theme/variables/spacers";
@use "assets/theme/variables/sizes";

.root {
  margin: auto;
  max-width: sizes.$contentMaxWidth;
  padding: spacers.$spacer21 spacers.$spacer6;

  @media screen and (max-width: breakpoints.$screenMd){
    padding: spacers.$spacer16 spacers.$spacer6;
  }
}

.fullWidth {
  max-width: 100%;
}

.fullHeight {
  height: 100%;

  > :global(.ant-col) {
    height: 100%;
  }
}
