@use 'assets/theme/variables/breakpoints';
@use 'assets/theme/variables/spacers';
@use 'assets/theme/variables/sizes';
@use 'assets/theme/variables/palette';
@use 'assets/theme/variables/borders';

$labelWidth: 120px;

.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  max-width: sizes.$contentMaxWidth;
  padding: spacers.$spacer18 spacers.$spacer6 !important;
}

.col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: breakpoints.$screenLg){
    height: auto;
  }
}

.space {
  margin-bottom: spacers.$spacer16;

  @media screen and (max-width: breakpoints.$screenMd){
    margin-bottom: spacers.$spacer11;
    gap: spacers.$spacer11 !important;
  }
}

.documentationSpace {
  justify-content: space-between;

  @media screen and (max-width: breakpoints.$screenMd){
    margin-bottom: spacers.$spacer11;
    gap: spacers.$spacer11 !important;
  }

  @media screen and (max-width: breakpoints.$screenLg) and (min-width: breakpoints.$screenMd){
    flex-direction: row;
  }
}

.formCol {
  width: 100%;
  max-width: 740px;

  @media screen and (max-width: breakpoints.$screenLg){
    max-width: 100%;
    margin-bottom: spacers.$spacer16;
  }

  @media screen and (max-width: breakpoints.$screenXs){
    max-width: 100%;
    margin-bottom: spacers.$spacer11;
  }
}

.formContainer {
  background-color: palette.$placeboBlue;
  padding: spacers.$spacer16 spacers.$spacer11;
  border-radius: borders.$borderRadiusXXL;

  @media screen and (max-width: breakpoints.$screenMd){
    padding: spacers.$spacer4 spacers.$spacer6;
  }
}

.form {
  width: 100%;

  :global(.ant-form-item-label) {
    width: $labelWidth;

    > label {
      font-weight: 400;
    }
  }

  :global(.ant-form-item) {
    margin-bottom: spacers.$spacer6;
  }
}

.formActions {
  margin-left: $labelWidth;

  @media screen and (max-width: breakpoints.$screenMd){
    margin-left: 0;
  }
}
