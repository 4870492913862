@use 'assets/theme/variables/breakpoints';
@use 'assets/theme/variables/spacers';
@use 'assets/theme/variables/sizes';

.logo {
  min-width: min-content;
  > a {
    display: flex;
    align-items: center;
  }
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  max-height: sizes.$headerHeight;
  max-width: sizes.$contentMaxWidth;
  padding: 0 spacers.$spacer6 !important;
}

.avatar {
  cursor: pointer;
}

.menu {
  border-top-left-radius: 12px;

  :global(.ant-drawer-header) {
    border-bottom: none;
  }

  :global(.ant-drawer-header-title) {
    flex-direction: row-reverse;
  }
}
