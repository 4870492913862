@use "assets/theme/variables/spacers";
@use "assets/theme/variables/breakpoints";
@use "assets/theme/variables/borders";
@use "assets/theme/variables/palette";
@use "assets/theme/variables/font";

$rightContainerMaxWidth: 960px;

.root {
  height: fit-content;
}

.cover {
  width: 100%;
  padding-bottom: 300px;
  display: flex;
  flex-direction: column;
  position: relative;

  > div:first-child {
    z-index: 10;
  }
}

.logoLabel {
  display: block;
  font-size: 110px;
  font-weight: 500;
  line-height: 134px;
  background: -webkit-linear-gradient(78.54deg, #699FA1 28.2%, #C4E684 83.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: spacers.$spacer3;

  @media screen and (max-width: breakpoints.$screenMd){
    font-size: 55px;
    line-height: 67px;
  }
}

.logoDescription {
  @media screen and (max-width: breakpoints.$screenMd){
    font-size: 18px;
  }
}

.title {
  @media screen and (max-width: breakpoints.$screenMd){
    font-size: 14px;
  }
}

.titleCol {
  width: 280px;
  max-width: 280px;
  margin-bottom: spacers.$spacer6;
}

.logoLeftContainer {
  @extend .titleCol;

  padding-top: spacers.$spacer9;
}

.logoRightContainer {
  max-width: $rightContainerMaxWidth;
}

.descriptionItem {
  width: 300px;

  a {
    font-size: font.$fontSizeLg;
  }

  @media screen and (max-width: breakpoints.$screenMd) {
    width: 100%;
  }
}

.descriptionRow {
  margin-top: spacers.$spacer19;

  @media screen and (max-width: breakpoints.$screenLg){
    justify-content: flex-start;

    > div:not(:last-child) {
      margin-right: spacers.$spacer8;
    }
  }

  @media screen and (max-width: breakpoints.$screenMd){
    > div:not(:last-child) {
      margin-right: 0;
      margin-bottom: spacers.$spacer8;
    }
  }
}

.logo {
  height: 360px;
  width: 150vw;
  position: absolute;
  left: -55%;
  bottom: 0;
  background-image: url("/assets/icons/cover.png");
  background-repeat: repeat-x;
  background-position: center top;
}

.info {
  width: 100%;
  padding-top: 100px;
}

.infoRightContainer {
  max-width: $rightContainerMaxWidth;

  > div:not(:last-child) {
    margin-bottom: spacers.$spacer6;
  }

  @media screen and (max-width: breakpoints.$screenLg){
    width: 100%;
  }
}

.infoRow {
  > div {
    height: 100%;
  }

  @media screen and (max-width: breakpoints.$screenLg){
    width: 100%;
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
}

.inverseRow {
  display: flex;
  flex-direction: row-reverse;
}

.infoRowItemLeft {
  border-radius: borders.$borderRadiusXXL;
  background-color: palette.$placeboBlue;
  padding-right: spacers.$spacer4;
  width: 610px;
  height: 120px;

  > div:first-child {
    height: 100%;
  }

  @media screen and (max-width: breakpoints.$screenLg){
    width: 100%;
  }

  @media screen and (max-width: breakpoints.$screenMd){
    flex-direction: column;
    height: auto;
    padding-right: 0;

    > div {
      width: 100%;
    }

    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: borders.$borderRadiusXXL;
    }
  }
}

.infoRowItemLeftText {
  @media screen and (max-width: breakpoints.$screenMd){
    padding: 0 spacers.$spacer6 spacers.$spacer6 spacers.$spacer6;
  }
}

.infoRowItemRight {
  height: 120px;
  justify-content: center;
  border-radius: borders.$borderRadiusXXL;
  border: 1px solid palette.$pineGreen300;
  background-color: palette.$aliceBlue;
  padding: 0 spacers.$spacer8;
  width: 330px;

  @media screen and (max-width: breakpoints.$screenLg){
    width: 100%;
  }
}

.cost {
  width: 100%;
  padding-top: 100px;
}

.costRightContainer {
  max-width: $rightContainerMaxWidth;
}

.costDescriptionRow {
  @media screen and (max-width: breakpoints.$screenLg){
    justify-content: flex-start;

    > div:not(:last-child) {
      margin-right: spacers.$spacer8;
    }
  }

  @media screen and (max-width: breakpoints.$screenMd){
    > div:not(:last-child) {
      margin-right: 0;
      margin-bottom: spacers.$spacer8;
    }
  }
}

.costDescriptionItem {
  @media screen and (max-width: breakpoints.$screenMd) {
    width: 100%;
  }
}

.costTitle {
  font-size: 45px;
  font-weight: 400;
  line-height: 54.46px;
}
