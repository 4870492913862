@use "sass:color";

$white: white;
$black: black;

// Grays
$gray50: #F9F9F9;
$gray100: #E6E7E8;
$gray150: #EAEAEA;
$gray200: #CDCED1;
$gray300: #B4B6BA;
$gray400: #9B9DA3;
$gray500: #82848B;
$gray600: #6A6C74;
$gray650: #6D6D6D;
$gray700: #51545D;
$gray800: #383B46;
$gray900: #060A18;

$platinum100: #F5F7F8;
$platinum200: #ECEFF1;
$platinum300: #E2E7EA;
$platinum400: #D9DFE3;
$platinum500: #CFD7DC;
$platinum600: #B1BABF;
$platinum700: #929CA2;
$platinum800: #747F86;
$platinum900: #556169;

$jet50: #F1F0F0;
$jet800: #32302B;

$devysGray300: #8C9193;
$devysGray500: #4F5759;
$devysGray700: #2F3435;

// Blue
$blue50: #F9FBFC;
$blue100: #F3F6F9;
$blue200: #E7EDF4;
$blue300: #DCE5EE;
$blue500: #C4D3E3;
$blue600: #A5B5C7;
$blue700: #8698AB;
$blue800: #677A8E;

$azure50: #EBF2FD;
$azure100: #D7E5FB;
$azure200: #AECBF8;
$azure300: #86B1F4;
$azure400: #5D97F1;
$azure500: #357DED;

$prussianBlue50: #D2E4EF;
$prussianBlue800: #193247;

$argentinianBlue500: #5EADF6;

$placeboBlue: #EDF2FD;
$aliceBlue: #F5F8FB;

// Red
$poppy50: #FCE8EB;
$poppy100: #F9D2D7;
$poppy200: #F3A5AE;
$poppy300: #ED7786;
$poppy400: #E74A5D;
$poppy500: #E11D35;

// Green
$forestGreen50: #DEF2E2;
$forestGreen800: #1D3829;
$pigmentGreen: #41A956;

$pineGreen25: #B3FAF2;
$pineGreen50: #A2EDE5;
$pineGreen100: #91E1D8;
$pineGreen300: #4DAEA3;
$pineGreen500: #097C6F;
$pineGreen600: #076359;
$pineGreen700: #054A43;

$verdigris25: #F9FBFB;
$verdigris50: #F0F5F6;
$verdigris100: #E1ECEC;
$verdigris300: #A5C5C7;
$verdigris500: #699FA1;
$verdigris600: #547F81;
$verdigris700: #3F5F61;

// Brown
$sealBrown50: #F9DEC9;
$sealBrown800: #49280E;

$fulvous500: #DD8627;
$eerieBlack500: #262626;

$linear: linear-gradient(286.81deg, rgba(219, 219, 219, 0.08) 0.01%, rgba(255, 255, 255, 0.317493) 32.01%, rgba(230, 230, 230, 0.5) 79.32%, rgba(230, 230, 230, 0.08) 114.37%);
$gradient: linear-gradient(106.85deg, #699FA1 38.85%, #C4E684 128.8%);
